<template>
  <div id="media-new">
    <div class="card custom-card">
      <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`">
        <div class="card-image">
          <b-image
            :src="getGhostImage(news.feature_image, 600)"
            :srcset="getGhostImageSet(news.feature_image, [300, 600])"
            ratio="16by9"
            :alt="news.title"
            class="isLoaded"
            lazy
          ></b-image>
        </div>
      </a>
      <div class="card-content">
        <div class="show-business">
          <p class="" v-for="(category, index) in news.tags" :key="index">
             <a :href="linkName(category.slug)">{{ checkName(category.slug) }}</a>
          </p>
        </div>
        <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`" class="title">{{
          news.title
        }}</a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
</style>
<script>
export default {
  props: ["news",'routeName'],
  methods: {
    checkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return str[1];
      } else {
        return str[0];
      }
    },
    linkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return "/category/" + str[0] + "/" + str[1];
      } else {
        return "/category/" + str[0];
      }
    },
  },
};
</script>

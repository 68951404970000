<template>
  <div>
    <div class="box-news">
      <div class="columns">
        <div class="column">
          <div class="column is-full mb-1 name-in-mobile">
            <div class="block-title">
              <span>Interesting</span>
            </div>
          </div>
          <div>
            <b-loading
              v-model="loading"
              :is-full-page="false"
              style="min-height: 50px; position: relative"
            >
            </b-loading>
            <div class="columns set-flex" v-if="loading == false">
              <div
                class="column is-one-third"
                v-for="(data_news, index) in blogInteresting"
                :key="index"
              >
                <CardNews :news="data_news" routeName="interesting" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardNews from "@/components/Card";
import { BLOG } from "@/service/axios.js";
export default {
  components: {
    CardNews,
  },
  data() {
    return {
      blogInteresting: [],
      loading: true,
    };
  },
  mounted() {
    this.getBlogInteresting();
  },
  methods: {
    getBlogInteresting() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=3b&filter=tag:interesting&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogInteresting = res.data.posts;
            this.loading = false;
            console.log("blogInteresting", this.blogInteresting);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>
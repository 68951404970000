<template>
  <div id="home-business">
    <div class="box-news">
      <div class="columns">
        <div class="column is-full">
          <div class="block-title">
            <span>Business contents</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
        <b-loading v-model="loading" :is-full-page="false" > </b-loading>
          <div class="columns set-flex" v-if="loading == false">
            <div
              class="column is-half-desktop is-half-tablet"
              v-for="(data_news, index) in blogBusiness"
              :key="index"
            >
              <CardNews :news="data_news" routeName="business" />
            </div>
          </div>
        </div>
        <!-- <div class="column">
          <div
            class="box-social"
            v-for="(data_social, index) in socialList"
            :key="index"
          >
            <Social :social="data_social" />
          </div>
        </div> -->
        <div class="column" style="padding:0;padding-bottom: 30px">
          <div class="column is-full">
            <div
              class="box-social"
              v-for="(data_social, index) in socialList"
              :key="index"
            >
              <Social :social="data_social" />
            </div>
          </div>
          <div class="box-popular">
            <div class="column is-full" v-if="loading == false">
              <HomePopular :blogPopular="blogPopular" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardNews from "@/components/Card";
import Social from "@/components/Social";
import HomePopular from "@/components/HomePopular";
import { BLOG } from "@/service/axios.js";
export default {
  computed: {
    socialList() {
      return this.$store.state.socialList;
    },
  },
  data() {
    return {
      loading: true,
      blogBusiness: [],
      blogPopular: [],
    };
  },
  components: {
    CardNews,
    Social,
    HomePopular,
  },
  mounted() {
    this.getBlogBusiness();
    this.getBlogPopular();
  },
  methods: {
    getBlogBusiness() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=6b&filter=tag:business&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogBusiness = res.data.posts;
            this.loading = false;
            console.log(this.blogBusiness);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    getBlogPopular() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=5b&filter=featured:true&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogPopular = res.data.posts;

            this.loading = false;
            // console.log("blogPopular", this.blogPopular);
            // var getAll = this.blogPopular;
            // this.shuffle(getAll);
            // this.Interesting = getAll.slice(1, 3);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>
<template>
  <div>
    <div class="column is-full p-0">
      <div class="block-title-r">POPULAR LAST 7 DAYS</div>
      <div v-for="(data_news, index) in blogPopular" :key="index">
        <div class="card custom-card" v-if="index < 1">
          <a :href="`/detail/${data_news.tags[0].slug}/${data_news.slug}`">
            <div class="card-image">
              <b-image
                :src="getGhostImage(data_news.feature_image, 600)"
                :srcset="getGhostImageSet(data_news.feature_image, [300, 600])"
                ratio="16by9"
                :alt="data_news.title"
                class="isLoaded"
                lazy
              ></b-image>
            </div>
          </a>
          <div class="card-content">
            <div class="show-business">
              <p
                class=""
                v-for="(category, index) in data_news.tags"
                :key="index"
              >
                <a :href="linkName(category.slug)">{{ checkName(category.slug) }}</a>
              </p>
            </div>
            <a :href="`/detail/${data_news.tags[0].slug}/${data_news.slug}`" class="title">{{
              data_news.title
            }}</a>
            <div class="content">
              <p>{{ data_news.excerpt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-full popular-seven-day p-0">
      <div
        class="columns set-flex"
        v-for="(data_news, index) in blogPopular"
        :key="index"
      >
        <NewsMiniSite :news="data_news" v-if="index > 0" :routeName="data_news.tags[0].slug" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.block-title-r {
  color: #0f4c82;
  font-size: 17px;
  margin-bottom: 22px;
  font-weight: 400;
  position: relative;
  line-height: 37px;

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f5f5f5;
  }
  &:after {
    content: "";
    width: 50px;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    margin: auto;
    background-color: #0f4c82;
  }
}
</style>
<script>
import NewsMiniSite from "@/components/NewsMiniSite";
export default {
  props: ["blogPopular"],
  components: {
    NewsMiniSite,
  },
  data() {
    return {};
  },
  mounted() {
    // this.loadDataPopular();
    // console.log(this.blogPopular);
  },
  methods: {
    checkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return str[1];
      } else {
        return str[0];
      }
    },
    linkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return "/category/" + str[0] + "/" + str[1];
      } else {
        return "/category/" + str[0];
      }
    },
  },
};
</script>
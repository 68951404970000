<template>
  <div id="home-academy">
    <div class="box-news">
      <div class="columns">
        <div class="column is-full name-in-mobile">
          <div class="block-title">
            <span>Academy</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-loading
            v-model="loading"
            :is-full-page="false"
            style="min-height: 50px; position: relative"
          >
          </b-loading>
          <div class="columns set-flex" v-if="loading == false">
            <div
              class="column is-half"
              v-for="(data_news, index) in blogAcademy"
              :key="index"
            >
              <CardAcademy :news="data_news" routeName="academy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardAcademy from "@/components/CardAcademy.vue";
import { BLOG } from "@/service/axios.js";
export default {
  data() {
    return {
      blogAcademy: [],
      loading: true,
    };
  },
  components: {
    CardAcademy,
  },
  mounted() {
    this.getBlogAcademy();
  },
  methods: {
    getBlogAcademy() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=2b&filter=tag:academy&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogAcademy = res.data.posts;
            this.loading = false;
            console.log("academy", this.blogAcademy);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>
<template>
  <div id="home-media">
    <div class="box-news">
      <div class="columns">
        <div class="column is-full name-in-mobile">
          <div class="block-title">
            <span>Media</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <div class="list-title">
            <span>Activity News</span>
            <span>
              <a href="/category/media/news" class="pulldown-category">
                <span class="another">ดูเพิ่มเติมในหมวดนี้</span>
                <i class="fas fa-long-arrow-alt-right"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-loading
            v-model="loading1"
            :is-full-page="false"
            style="min-height: 50px; position: relative"
          >
          </b-loading>
          <div class="columns set-flex" v-if="loading1 == false">
            <div
              class="column is-one-third"
              v-for="(data_news, index) in blogMediaNews"
              :key="index"
            >
              <CardMediaNew
                :news="data_news"
                v-if="
                  widthMax < 767.98 && index < 1
                    ? true
                    : widthMax > 768
                    ? true
                    : false
                "
                routeName="media"
              />
              <div
                class="column is-full popular-seven-day"
                v-if="widthMax < 767.98 && index > 0 ? true : false"
              >
                <div class="columns set-flex is-0">
                  <NewsMiniSite :news="blogMediaNews[index]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="solid-bar"></div>
      <div class="columns">
        <div class="column is-full">
          <div class="list-title">
            <span>Youtube channel</span>
            <span>
              <a href="/category/media/youtube" class="pulldown-category">
                <span class="another">ดูเพิ่มเติมในหมวดนี้</span>
                <i class="fas fa-long-arrow-alt-right"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-loading
            v-model="loading2"
            :is-full-page="false"
            style="min-height: 50px; position: relative"
          >
          </b-loading>
          <div class="columns set-flex" v-if="loading2 == false">
            <div
              class="column is-one-third"
              v-for="(data_news, index) in blogMediaYoutube"
              :key="index"
            >
              <CardMediaYoutube :news="data_news" routeName="media" />
            </div>
          </div>
        </div>
      </div>
      <div class="solid-bar"></div>
      <div class="columns">
        <div class="column is-full">
          <div class="list-title">
            <span>Podcast</span>
            <span>
              <a href="/category/media/podcast" class="pulldown-category">
                <span class="another">ดูเพิ่มเติมในหมวดนี้</span>
                <i class="fas fa-long-arrow-alt-right"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-loading
            v-model="loading3"
            :is-full-page="false"
            style="min-height: 50px; position: relative"
          >
          </b-loading>
          <div class="columns set-flex" v-if="loading3 == false">
            <div
              class="column is-one-third"
              v-for="(data_news, index) in blogMediaPodcast"
              :key="index"
            >
              <CardMediaPodcast :news="data_news" routeName="media" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.solid-bar {
  width: 100%;
  height: 2px;
  background: #0f4c82;
  border-radius: 10px;
  margin: 20px 0 25px;
}
</style>
<script>
import CardMediaNew from "@/components/CardMediaNew";
import NewsMiniSite from "@/components/NewsMiniSite";
import CardMediaYoutube from "@/components/CardMediaYoutube";
import CardMediaPodcast from "@/components/CardMediaPodcast";
import { BLOG } from "@/service/axios.js";
export default {
  components: {
    CardMediaNew,
    NewsMiniSite,
    CardMediaYoutube,
    CardMediaPodcast,
  },
  data() {
    return {
      widthMax: window.innerWidth,
      blogMediaNews: [],
      blogMediaYoutube: [],
      blogMediaPodcast: [],
      loading1: true,
      loading2: true,
      loading3: true,
    };
  },
  mounted() {
    this.getBlogMediaNews();
    this.getBlogMediaYoutube();
    this.getBlogMediaPodcast();
  },
  methods: {
    getBlogMediaNews() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=3b&filter=tag:media_news&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogMediaNews = res.data.posts;
            this.loading1 = false;
            console.log("blogMediaNews", this.blogMediaNews);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    getBlogMediaYoutube() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=3b&filter=tag:media_youtube&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogMediaYoutube = res.data.posts;
            this.loading2 = false;
            console.log("blogMediaYoutube", this.blogMediaYoutube);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    getBlogMediaPodcast() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=3b&filter=tag:media_podcast&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogMediaPodcast = res.data.posts;
            this.loading3 = false;
            console.log("blogMediaPodcast", this.blogMediaPodcast);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>
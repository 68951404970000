
<template>
  <div class="box-started">
    <div id="home-page" class="container">
      <HomeBusiness />
      <HomeAcademy />
      <HomeMedia />
      <HomeDigital />
      <HomeInteresting />
    </div>
  </div>
</template>
<script>
import HomeBusiness from "@/components/HomeBusiness";
import HomeAcademy from "@/components/HomeAcademy";
import HomeMedia from "@/components/HomeMedia";
import HomeDigital from "@/components/HomeDigital";
import HomeInteresting from "@/components/HomeInteresting";
export default {
  data() {
    return {
    }
  },
  components: {
    HomeBusiness,
    HomeAcademy,
    HomeMedia,
    HomeDigital,
    HomeInteresting,
  },
};
</script>
<template>
  <div class="social-list">
    <div class="td-list tab-icon">
      <img src="../assets/icon/line.svg" class="line" v-if="social.nameClass == 'line'" />
      <span class="icon" :class=" social.nameClass" v-else>
        <i :class="social.icon"></i>
      </span>
    </div>
    <div class="tab-social">
      <a :href="`${social.link}`" target="_blank">
        <div class="space-social">
          <div class="td-list">
            <span>{{social.count | comma}}</span>
            <span>{{social.way}}</span>
          </div>
          <div class="td-list state-list">{{social.click}}</div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: ["social"],
};
</script>

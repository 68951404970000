<template>
  <div class="card custom-card academy">
    <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`">
      <div class="card-image">
        <!-- <div class="show-business">
          <span>BUSINESS</span>
        </div> -->
        <b-image
          :src="getGhostImage(news.feature_image, 600)"
          :srcset="getGhostImageSet(news.feature_image, [300, 600])"
          ratio="16by9"
          :alt="news.title"
          class="isLoaded"
          lazy
        ></b-image>
      </div>
    </a>
    <div class="card-content">
      <div class="show-business">
        <p class="" v-for="(category, index) in news.tags" :key="index">
           <a :href="linkName(category.slug)">{{ checkName(category.slug) }}</a>
        </p>
      </div>
      <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`" class="title">{{ news.title }}</a>
      <div class="content">
        <p>{{ news.excerpt }}</p>
      </div>
      <p class="see-more">
        <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`">Read more</a>
      </p>
      <!-- <div class="text-center">
        <button class="btn-look-click">สนใจคลาส คลิก</button>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["news", "routeName"],
  methods: {
    checkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return str[1];
      } else {
        return str[0];
      }
    },
    linkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return "/category/" + str[0] + "/" + str[1];
      } else {
        return "/category/" + str[0];
      }
    },
  },
};
</script>

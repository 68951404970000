<template>
  <div id="media-new">
    <div class="card custom-card">
       <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`">
        <div class="card-image">
          <b-image
            :src="getGhostImage(news.feature_image, 600)"
            :srcset="getGhostImageSet(news.feature_image, [300, 600])"
            ratio="16by9"
            :alt="news.title"
            class="isLoaded icon-play"
            lazy
          ></b-image>
        </div>
      </a>
      <div class="card-content">
        <div class="show-business">
          <p class="" v-for="(category, index) in news.tags" :key="index">
           <a :href="linkName(category.slug)">{{ checkName(category.slug) }}</a>
          </p>
        </div>
        <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`" class="title">{{
          news.title
        }}</a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.icon-play {
  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 10px;
  }
  &::after {
    content: "";
    background-image: url("../assets/icon/play.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 100%;
    max-width: 30px;
    max-height: 30px;
    height: 100%;
    z-index: 1;
  }
}
</style>
<script>
export default {
  props: ["news",'routeName'],
  methods: {
    checkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return str[1];
      } else {
        return str[0];
      }
    },
    linkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return "/category/" + str[0] + "/" + str[1];
      } else {
        return "/category/" + str[0];
      }
    },
  },
};
</script>

<template>
  <div id="media-new">
    <div class="card custom-card">
      <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`">
        <div class="card-image">
          <figure class="image is-16by9 bg-fade">
            <img :src="news.feature_image" alt class="isLoaded" />
            <div class="card-content">
              <img src="../assets/icon/play.png" alt="" class="img-podcast" />
              <a :href="`/detail/${routeName.toLowerCase()}/${news.slug}`" class="title">
                {{ news.title }}</a
              >
            </div>
          </figure>
        </div>
      </a>
      <div class="card-content">
        <div class="show-business">
          <p class="" v-for="(category, index) in news.tags" :key="index">
            <a :href="linkName(category.slug)">{{ checkName(category.slug) }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.bg-fade {
  &::after {
    content: "";
    background-image: linear-gradient(
      0deg,
      #000,
      rgba(0, 0, 0, 0.560784),
      rgba(0, 0, 0, 0.294118),
      rgba(0, 0, 0, 0.0745098),
      transparent
    );
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 10px;
  }

  .img-podcast {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 20px;
    max-height: 20px;
    object-fit: cover;
    left: 10px !important;
    top: 8px !important;
  }
  .card-content {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 8px 15px 15px 35px;
    .title,
    .title:hover {
      color: #fff;
      margin: 0;
      font-size: 18px;
      width: 100%;
    }
    z-index: 2;

    &::after {
      content: "Podcast";
      background: #fee03f;
      top: -16px;
      left: 10px !important;
      position: absolute;
      padding: 0 4px;
      font-size: 12px;
      color: #333333;
      margin: 0;
    }
  }
}
</style>
<script>
export default {
  props: ["news",'routeName'],
  methods: {
    checkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return str[1];
      } else {
        return str[0];
      }
    },
    linkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return "/category/" + str[0] + "/" + str[1];
      } else {
        return "/category/" + str[0];
      }
    },
  },
};
</script>
